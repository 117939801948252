<offconon-shared-ui-search-box>
  <div class="flex" flex-wrap [formGroup]="form">
    <div class="w-full">
      <div class="">
        <div class="m-2">
          <div class="flex flex-col lg:flex-row items-center justify-between mb-3">
            <div class="text-center lg:text-left lg:pr-8">
              <div class="font-bold mt-3 mb-1 tex-lg">
                {{ 'Search' | translate }}
              </div>
            </div>
          </div>

          <div class="grip w-full max-w-full">
            <div class="flex flex-col">
              <div class="field w-full">
                <span class="p-float-label">
                  <input
                    formControlName="ad_name"
                    type="text"
                    pInputText
                    class="w-full"
                    placeholder="Type something to get started" />
                </span>
              </div>
              @if (this.form.value['ad_name']) {
                <div class="field w-full">
                  <div class="flex flex-row-reverse flex-wrap">
                    <div class="flex items-center justify-center">
                      <a
                        pButton
                        icon="pi pi-trash"
                        iconPos="left"
                        class="px-5 py-3 p-button-outlined p-button-rounded p-button-danger font-bold w-auto white-space-nowrap"
                        style="white-space: nowrap"
                        label="{{ 'Reset search' | translate }}"
                        (click)="resetForm()"></a>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</offconon-shared-ui-search-box>
