<div class="text-sm md:text-base mb-3 mt-2">
  <span class="font-bold"> {{ 'Buyer' | translate }}:</span> {{ shopper_name }}
  @if (buyer_business_id) {
    <span class="text-red-500 text-sm"> / ({{ 'Purchase as business' | translate }}) </span>
  }
</div>
<p-divider class="w-full" />
@if (searchfilter) {
  <offconon-shopping-cart-search-common />
}

<div class="flex justify-center flex-wrap">
  <ngx-masonry
    [ordered]="true"
    [options]="{
      horizontalOrder: false,
      originLeft: true,
      fitWidth: true,
      gutter: 10
    }">
    @if (system_datas && !loading) {
      @for (system_data of system_datas; track system_data) {
        <div masonryLazy loading="lazy" ngxMasonryItem class="masonry-item w-full sm:w-auto">
          <offconon-shopping-cart-card-common
            [system_data]="system_data"
            [currentUser]="currentUser" />
        </div>
      }
    } @else if (!system_datas || loading) {
      @for (skel of 9 | createArray; track skel) {
        <div masonryLazy loading="lazy" ngxMasonryItem class="masonry-item w-full sm:w-auto">
          <offconon-skeleton-category-render-sample-card [numberOfSkeletonCard]="1" />
        </div>
      }
    }
  </ngx-masonry>
</div>

@if (result?.total_objects > 10) {
  <div class="flex justify-center flex-wrap pb-5 pt-5">
    <div class="flex items-center justify-center">
      <p-paginator
        [styleClass]="loading ? 'pointer-events-none' : ''"
        [rows]="result?.page_size"
        [totalRecords]="result?.total_objects"
        [rowsPerPageOptions]="[10, 20, 30]"
        (onPageChange)="paginationChanged($event)" />
    </div>
  </div>
}
@if (system_datas && system_datas.length === 0) {
  <div class="text-center mt-4">
    <div class="text-red-500 text-lg m-2 pb-3">
      {{ 'No results!' | translate }}
    </div>
    <p class=" ">
      {{ 'Sorry, we could not find what you were looking for!' | translate }}
    </p>
  </div>
}

<p-confirmDialog
  key="shoppingSumCartCardDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [baseZIndex]="2"
  [appendTo]="'body'">
  <ng-template #headless let-message let-onAccept="onAccept" let-onReject="onReject">
    <div class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded">
      <span class="font-bold text-2xl block mb-2 mt-6">{{ message?.header }}</span>
      <p class="mb-0">{{ message?.message }}</p>
      <div class="flex items-center gap-2 mt-6">
        <p-button label="{{ 'Yes, I want to delete it.' | translate }}" (onClick)="onAccept()" />
        <p-button label="{{ 'Cancel' | translate }}" [outlined]="true" (onClick)="onReject()" />
      </div>
    </div>
  </ng-template>
</p-confirmDialog>

<p-confirmDialog
  key="shoppingSumCartCardDialog"
  [breakpoints]="{ '960px': '75vw' }"
  [baseZIndex]="2"
  [appendTo]="'body'">
  <ng-template #headless let-message let-onAccept="onAccept" let-onReject="onReject">
    <div class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded">
      <span class="font-bold text-2xl block mb-2 mt-6">{{ message?.header }}</span>
      <p class="mb-0">{{ message?.message }}</p>
      <div class="flex items-center gap-2 mt-6">
        <p-button label="{{ 'Yes, I want to delete it.' | translate }}" (onClick)="onAccept()" />
        <p-button label="{{ 'Cancel' | translate }}" [outlined]="true" (onClick)="onReject()" />
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
