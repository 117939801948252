import {
  AfterViewInit,
  Component,
  DestroyRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { isLoginResponse, isUserBusiness } from 'libs/shared/data/stores/auth/auth.reducer';
import { getClientId, selectUserBusinessEntity } from 'libs/shared/data/stores/auth/auth.selectors';
import {
  resetSearchBoxSearchJsonAndPagination,
  setPagination,
} from 'libs/shared/ui/search-box/src/lib/shared-ui-search-box/data-access/store/search-box.actions';
import { getSearchBoxSearchJsonAndPagination } from 'libs/shared/ui/search-box/src/lib/shared-ui-search-box/data-access/store/search-box.selectors';
import { NgxMasonryComponent, NgxMasonryModule } from 'ngx-masonry';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { PaginatorModule } from 'primeng/paginator';
import { filter, Subscription, switchMap, tap } from 'rxjs';

import { AuthService } from '@offconon/core/features/auth';
import { LoginResponse } from '@offconon/core-api';
import { PaginatedShoppingCartList } from '@offconon/core-api';
import { ShoppingCart } from '@offconon/core-api';
import { ShoppingCartService } from '@offconon/core-api';
import { LayoutService } from '@offconon/shared/features/layout';
import { SkeletonCategoryRenderSampleCardComponent } from '@offconon/shared/ui/skeleton-render';
import { CreateArrayPipe } from '@offconon/shared/utils/pipes';
import {
  ShoppingCartService as CartHelperService,
  platform,
  PlatformService,
} from '@offconon/shared/utils/services';

import { ShoppingCartCardCommonComponent } from '../../card/shopping-cart-card-common/shopping-cart-card-common.component';
import { ShoppingCartSearchCommonComponent } from '../../search/shopping-cart-search-common/shopping-cart-search-common.component';

@Component({
  selector: 'offconon-shopping-cart-render-common',
  templateUrl: './shopping-cart-render-common.component.html',
  providers: [ConfirmationService],
  imports: [
    DividerModule,
    ShoppingCartSearchCommonComponent,
    NgxMasonryModule,
    ShoppingCartCardCommonComponent,
    SkeletonCategoryRenderSampleCardComponent,
    PaginatorModule,
    ConfirmDialogModule,
    CreateArrayPipe,
    TranslateModule,
    ButtonModule,
  ],
})
export class ShoppingCartRenderCommonComponent implements OnInit, AfterViewInit, OnDestroy {
  private authService = inject(AuthService);
  private store$ = inject(Store);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);

  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

  @Input() searchfilter = false;
  @Input() selectedBusiness: any;

  system_datas: ShoppingCart[] | any;

  private destroyRef = inject(DestroyRef);

  loading = false;
  result: any;

  buyer_user_id: any;
  buyer_user_token: any;
  buyer_business_id: any;
  currentUser: any;

  platform: platform;

  shopper_name: any;
  delete_system_data: any;
  delete_system_item_data: any;

  private shoppingCartService = inject(ShoppingCartService);
  private layoutService = inject(LayoutService);
  private translateService = inject(TranslateService);
  private cartHelperService = inject(CartHelperService);
  private platformService = inject(PlatformService);

  public user: LoginResponse | null = null;
  private systemDataSubscription: Subscription;

  constructor() {
    this.store$
      .select(getClientId)
      .pipe(
        filter((clientId) => clientId !== null),
        takeUntilDestroyed(),
      )
      .subscribe((clientId: any) => {
        this.buyer_user_token = clientId;
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.layoutService.setMasonry(this.masonry);
      this.layoutService.reloadMasonryLayout();
    }, 3000);
  }

  ngOnDestroy(): void {
    this.systemDataSubscription.unsubscribe();
    this.store$.dispatch(resetSearchBoxSearchJsonAndPagination());
  }

  ngOnInit() {
    this.platformService.platformObservable.subscribe({
      next: (platform) => {
        this.platform = platform;
      },
    });

    this.shopper_name = this.translateService.instant('Guest');

    if (this.platform === 'core') {
      this.authService.currentUserSubject.subscribe((user: any) => {
        this.user = user;
        if (this.user) {
          this.buyer_user_id = this.user.id;
          this.shopper_name = this.user.full_name;
          this.store$.select(selectUserBusinessEntity).subscribe({
            next: (res) => {
              if (isLoginResponse(res)) {
                this.currentUser = res;
              }

              if (isUserBusiness(res)) {
                this.selectedBusiness = res;
                this.buyer_business_id = res.business_id;
                this.shopper_name = res.business_name;
              }
            },
          });
        }
      });
    } else if (this.platform === 'erp') {
      if (this.selectedBusiness) {
        this.shopper_name = this.selectedBusiness.business_name;
        this.buyer_business_id = this.selectedBusiness.business_id;
      }
    }

    this.cartHelperService.provideShoppinCartItemDelete$.subscribe({
      next: (item_data) => {
        this.delete_system_item_data = item_data;
        if (this.delete_system_item_data && this.delete_system_item_data?.shopping_cart_item_id) {
          this.confirmItemDelete(this.delete_system_item_data);
          this.delete_system_item_data = null;
          this.cartHelperService.setShoppinCartDeleteItemData(null);
        }
      },
    });

    this.cartHelperService.provideShoppinCartDelete$.subscribe({
      next: (data) => {
        this.delete_system_data = data;
        if (this.delete_system_data && this.delete_system_data?.id) {
          this.confirmDelete(this.delete_system_data?.id);
          this.delete_system_data = null;
          this.cartHelperService.setShoppinCartDeleteData(null);
        }
      },
    });

    this.setCartList();
  }

  setCartList() {
    this.cartHelperService.shoppingCartListObservable.subscribe({
      next: () => {
        this.getSearchSystemData();
      },
    });
  }

  getSearchSystemData() {
    this.systemDataSubscription = this.systemDataSubscription = this.store$
      .select(getSearchBoxSearchJsonAndPagination)
      .pipe(
        tap(() => {
          this.loading = true;
        }),
        switchMap((res) => {
          return this.shoppingCartService.shoppingCartCartList(
            res.pagination ? res.pagination.page : 1,
            res.pagination ? res.pagination.page_size : 10,
            {
              user_token: this.buyer_user_token,
              buyer_business_id: this.buyer_business_id,
              ...res.searchJson,
            },
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (result: PaginatedShoppingCartList) => {
          this.system_datas = result.results;
          this.result = result;
          this.loading = false;
        },

        complete: () => {
          this.loading = false;
        },
      });
  }

  paginationChanged(event: any) {
    this.store$.dispatch(
      setPagination({
        pagination: { page: event.page + 1, page_size: event.rows },
      }),
    );
    window.scrollTo(0, 0);
  }

  confirmDelete(id: any) {
    this.confirmationService.confirm({
      message:
        this.translateService.instant('Are you sure you want to delete this?') +
        ' ' +
        this.translateService.instant('This decision can not be reversed!'),
      header: this.translateService.instant('Delete'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.shoppingCartService
          .shoppingCartCartDestroy(id)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              let foundIndex = this.system_datas.findIndex((x: any) => {
                return x.id == id;
              });

              this.system_datas.splice(foundIndex, 1);

              setTimeout(() => {
                this.layoutService.reloadMasonryLayout();
              }, 200);

              this.messageService.add({
                severity: 'info',
                summary: this.translateService.instant('Delete'),
                detail: this.translateService.instant('The item has been deleted!'),
              });
            },

            complete: () => {
              // this.cartHelperService.setShoppinCartDeleteData(null);
              //  this.cartHelperService.setShoppinCartList();
            },
          });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              severity: 'warn',
              summary: this.translateService.instant('Cancel'),
              detail: this.translateService.instant('This item has remained active!'),
            });
            break;
        }
      },
      key: 'shoppingSumCartCardDialog',
    });
  }

  confirmItemDelete(data: any) {
    this.confirmationService.confirm({
      message:
        this.translateService.instant('Are you sure you want to delete this?') +
        ' ' +
        this.translateService.instant('This decision can not be reversed!'),
      header: this.translateService.instant('Delete'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.shoppingCartService
          .shoppingCartCartDestroy(data?.shopping_cart_item_id)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (result: any) => {
              if (result && result?.ads) {
                let foundIndex = this.system_datas.findIndex((x: { id: any }) => x.id == result.id);
                this.system_datas[foundIndex] = result;
              } else {
                let foundIndex = this.system_datas.findIndex((x: any) => {
                  return x.id == data.id;
                });

                this.system_datas.splice(foundIndex, 1);

                setTimeout(() => {
                  this.layoutService.reloadMasonryLayout();
                }, 200);
              }

              this.messageService.add({
                severity: 'info',
                summary: this.translateService.instant('Delete'),
                detail: this.translateService.instant('The item has been deleted!'),
              });
            },
          });
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              severity: 'warn',
              summary: this.translateService.instant('Cancel'),
              detail: this.translateService.instant('This item has remained active!'),
            });
            break;
        }
      },
      key: 'shoppingItemDialog',
    });
  }
}
