import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

import {
  AbstractFilterComponent,
  SharedUiSearchBoxComponent,
} from '@offconon/shared/ui/search-box';

@Component({
  selector: 'offconon-shopping-cart-search-common',
  templateUrl: './shopping-cart-search-common.component.html',
  imports: [
    SharedUiSearchBoxComponent,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    TranslateModule,
  ],
})
export class ShoppingCartSearchCommonComponent
  extends AbstractFilterComponent<any>
  implements OnInit
{
  ngOnInit() {
    this.initForm();

    this.subscribeToFormValueChanges();
  }

  initForm() {
    this.form = this.fb.group({
      ad_name: [''],
    });
  }

  setSearchJson(formData: any) {
    this.searchJson = {};
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const elem = formData[key];
        switch (key) {
          case 'ad_name':
            this.searchJson[key] = elem;
            break;
          case 'page':
          case 'page_size':
            break;
          default:
            this.searchJson[key] = true;
            break;
        }
      }
    }
  }
}
