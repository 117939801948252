import { CurrencyPipe } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { variables } from 'libs/shared/utils/variables/variables';
import { MenuItem, SharedModule } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';

import { ShoppingCartItem } from '@offconon/core-api';
import { CountdownComponent } from '@offconon/shared/ui/countdown';
import { MonogramComponent } from '@offconon/shared/ui/monogram';
import { ShoppingCartCheckoutAdItemCommonComponent } from '@offconon/shared/ui/shopping-cart-checkout-ad-item-shared-common';
import { EnvironmentService } from '@offconon/shared/utils/services';
import { platform, PlatformService } from '@offconon/shared/utils/services';
import { ShoppingCartService as CartHelperService } from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-shopping-cart-card-common',
  templateUrl: './shopping-cart-card-common.component.html',
  imports: [
    CardModule,
    SharedModule,
    MonogramComponent,
    AvatarModule,
    MatIcon,
    TooltipModule,
    ButtonModule,
    MenuModule,
    CountdownComponent,
    ShoppingCartCheckoutAdItemCommonComponent,
    CurrencyPipe,
    TranslateModule,
  ],
})
export class ShoppingCartCardCommonComponent implements OnInit {
  private router = inject(Router);

  private environmentService = inject(EnvironmentService);
  @Input() system_data: ShoppingCartItem | any;
  @Input() currentUser: any;
  @Input() open_link = /ad-details/;
  items: MenuItem[];

  business_data: any;
  profile_link = '/';
  platform: platform;
  avatar_img: any;
  username: any;
  display_name: any;
  is_subscription = false;
  is_verified = false;
  subscription_type = 'Elite';
  subscription_icon = 'diamond';

  showMonogram = true;
  monogram_class = 'ml-auto mt-2';
  businessErpLink: any;

  private cartHelperService = inject(CartHelperService);
  private translateService = inject(TranslateService);
  private platformService = inject(PlatformService);

  ngOnInit() {
    this.platformService.platformObservable.subscribe({
      next: (platform) => {
        this.platform = platform;
      },
    });

    if (this.system_data?.ads[0]) {
      this.business_data = this.system_data?.ads[0]?.seller_business;
    }

    if (this.business_data) {
      this.avatar_img = this.business_data.business_avatar_path;
      if (this.avatar_img) {
        this.showMonogram = false;
      }

      this.username = this.business_data.business_mention_name;
      this.display_name = this.business_data.business_name;
      this.is_subscription = this.business_data.is_subscription;
      this.is_verified = this.business_data.business_is_verified;
      this.subscription_type = this.business_data.business_subscription_type;

      switch (this.subscription_type) {
        case 'Beginner': {
          this.subscription_icon = 'local_fire_department';
          break;
        }
        case 'Professional': {
          this.subscription_icon = 'tips_and_updates';
          break;
        }
        case 'Empire': {
          this.subscription_icon = 'rocket_launch';
          break;
        }
      }

      this.profile_link = '/business-profile/' + this.username;
    }

    if (this.is_subscription) {
      this.monogram_class = 'h-12 w-12 ml-auto mt-3';
    }

    this.items = [
      {
        label: this.translateService.instant('Options'),
        items: [
          {
            label: this.translateService.instant('Delete'),
            icon: 'pi pi-times',

            command: () => {
              this.delete();
            },
          },
          {
            label: this.translateService.instant('Add to favourites'),
            icon: 'pi pi-plus',

            command: () => {
              this.delete();
            },
          },
          {
            label: this.translateService.instant('Seller profile'),
            icon: 'pi pi-qrcode',

            command: () => {
              this.postEntityProfile();
            },
          },
        ],
      },
    ];
  }

  delete() {
    this.cartHelperService.setShoppinCartDeleteData(this.system_data);
  }

  checkout() {
    this.cartHelperService.closeShoppingCartPanel(true);

    if (this.platform === 'core' && this.system_data.buyer_business_id && this.currentUser) {
      this.businessErpLink = `https://${this.currentUser.mentionName}.${this.environmentService.environment.workroomDomain}/checkout/${this.system_data.id}?${variables.businessUrl}=${this.system_data.buyer_business_id}`;

      window.open(`${this.businessErpLink}`, '_blank');
    } else {
      this.router.navigate(['/checkout/' + this.system_data.id]);
    }
  }

  postEntityProfile() {
    this.router.navigate([this.profile_link]);
  }

  update(data: any) {
    this.system_data = data;
  }
}
