<div class="p-2" id="cardShoppingTarget{{ system_data.id }}">
  @if (system_data) {
    <p-card styleClass=" rounded-lg  mb-4 w-full sm:w-96 md:w-96">
      <ng-template #header>
        <div class="border-b-2 border-slate-200">
          <div class="m-2">
            <div class="mb-1 flex items-center justify-between cursor-pointer">
              <div class="flex items-center" (click)="postEntityProfile()">
                @if (showMonogram) {
                  <offconon-monogram
                    [avatarClass]="monogram_class"
                    [options]="{ size: '3.2rem' }"
                    [name]="display_name" />
                }
                @if (!showMonogram) {
                  <p-avatar
                    size="large"
                    shape="circle"
                    image="{{ avatar_img }}"
                    styleClass="ml-auto {{ is_subscription ? 'mt-3' : 'mt-2' }}" />
                }
                <div class="ml-1">
                  <span class="block mb-1 text-sm font-medium white-space-nowrap">
                    {{ display_name }}

                    @if (is_verified) {
                      <sup>
                        <span class="text-teal-500 cursor-pointer">
                          <mat-icon
                            style="height: initial; width: initial; font-size: 10px"
                            fontIcon="check_circle"
                            tooltipPosition="top"
                            [appendTo]="'body'"
                            [tooltipZIndex]="'99999'"
                            pTooltip=" {{ 'Verified' | translate }}" />
                        </span>
                      </sup>
                    }
                    @if (is_subscription) {
                      <mat-icon
                        class="ml-1 text-blue-500 text-lg"
                        tooltipPosition="right"
                        fontIcon="{{ subscription_icon }}"
                        [appendTo]="'body'"
                        [tooltipZIndex]="'9999999999999999999999999'"
                        pTooltip="{{ subscription_type | translate }} - {{
                          'Premium member' | translate
                        }}" />
                    }
                  </span>
                  <p class="m-0 text-xs text-primary">&#64;{{ username }}</p>
                </div>
              </div>
              <div>
                <button
                  pButton
                  icon="pi pi-ellipsis-v"
                  class="p-button-text p-button-plain p-button-rounded"
                  (click)="menu.toggle($event)"></button>
                <p-menu #menu [appendTo]="'body'" [popup]="true" [model]="items" />
              </div>
            </div>

            @if (system_data?.expires_at) {
              <div class="text-xs text-center mb-1">
                {{ 'Time left to finish the checkout process:' | translate }}
              </div>
              <div class="text-xs text-teal-500 mb-2 text-center">
                <i class="pi pi-clock mr-1 ml-1 text-xs"></i>

                <offconon-countdown [end_time]="system_data?.expires_at" />
              </div>
            }
          </div>
        </div>
      </ng-template>
      <div class="mb-2 mt-2">
        @if (system_data) {
          @for (ad_system_data of system_data.ads; track system_data) {
            <offconon-shopping-cart-checkout-ad-item-common
              [open_link]="open_link"
              [system_data]="system_data"
              [ad_system_data]="ad_system_data"
              (formSavedEvent)="update($event)" />
          }
        }

        <div class="field mb-2 w-full border-t border-slate-200">
          <div class="flex justify-between mt-3">
            <span class="font-medium">{{ 'Total' | translate }}</span>
            <span class="font-bold">
              {{ system_data?.total?.amount * 1 | currency: system_data?.total?.currency }}</span
            >
          </div>
        </div>
      </div>
      <ng-template #footer>
        <div class="pb-2 mb-2 ml-2 mr-2 flex flex-wrap gap-3 justify-evenly">
          <button
            pButton
            class="px-5 py-3 p-button-outlined p-button-rounded font-bold w-auto white-space-nowrap"
            style="white-space: nowrap"
            (click)="checkout()">
            <mat-icon class="mr-1">point_of_sale</mat-icon> {{ 'Checkout' | translate }}
          </button>
        </div>
      </ng-template>
    </p-card>
  }
</div>
